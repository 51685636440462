<template>
    <div>
        <validation-observer ref="observer">
            <div
                v-if="errorMessage"
                class="alert alert-danger"
            >
                {{ errorMessage }}
            </div>
            <form
                :id="id"
                class="text-start"
                @submit.prevent="$emit('on-submit')"
                v-bind="$attrs"
            >
                <slot />
            </form>
        </validation-observer>
    </div>
</template>

<script>
    import { ValidationObserver } from 'vee-validate'
    import { logger } from '@/utils/logger'

    export default {
        name: 'FormContainer',
        inheritAttrs: false,
        components: {
            'validation-observer': ValidationObserver,
        },
        props: {
            id: {
                type: String,
                default: undefined,
            },
        },
        mounted() {
            logger.info(`FormContainer ID: ${this.id}`)
        },
        data() {
            return {
                errorMessage: null,
            }
        },
        methods: {
            applyGeneralError: function (errorMessage) {
                logger.info('Apply general error: ' + errorMessage)
                this.errorMessage = errorMessage
            },
            applyErrorOnField: function (errorObject) {
                logger.info('Apply error on field: ' + JSON.stringify(errorObject))
                this.$refs.observer.setErrors(errorObject)
            },
        },
    }
</script>
