<template>
    <marketing-layout :has-payment-calculator-footer="true">
        <marketing-hero>
            <h1 class="fw-bold heading-3 text-responsive mb-3">
                {{ $t('pages.marketing.paymentCalculator.title') }}
            </h1>
            <h2 class="fw-light heading-5 text-responsive mb-0">
                {{ $t('pages.marketing.paymentCalculator.subTitle') }}
            </h2>
        </marketing-hero>
        <section-block>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2 g-md-0">
                        <div>
                            <section-header variant="text-primary fw-bold text-center">
                                {{ $t('pages.marketing.paymentCalculator.cardDetailsSectionHeader') }}
                            </section-header>
                            <form-container
                                id="cardDetails"
                                ref="cardDetails"
                                data-testid="payment-calculator-form-container"
                            >
                                <div class="row row-cols-1 row-cols-sm-2 g-2">
                                    <form-field-currency
                                        v-model="cardBalance"
                                        :label="$t('pages.marketing.paymentCalculator.cardBalanceInputPlaceholder')"
                                        :placeholder="$t('pages.marketing.paymentCalculator.cardBalanceInputPlaceholder')"
                                        class="border-primary"
                                        name="cardBalance"
                                        validation-rules="required|currency: 50,250000"
                                        data-testid="form-field-currency-card-balance"
                                        @focus="clearRadioSelection"
                                    />
                                    <form-apr-field
                                        v-model="cardAPR"
                                        :label="$t('pages.marketing.paymentCalculator.cardAPRInputPlaceholder')"
                                        :placeholder="$t('pages.marketing.paymentCalculator.cardAPRInputPlaceholder')"
                                        class="border-primary"
                                        name="cardAPR"
                                        validation-rules="required|apr: 2.99,11.24"
                                        data-testid="form-field-apr-card-apr"
                                        @focus="clearRadioSelection"
                                    />
                                </div>
                            </form-container>
                        </div>

                        <div class="mt-5 mt-md-7">
                            <section-header variant="text-primary fw-bold text-center">
                                {{ $t('pages.marketing.paymentCalculator.paybackMethodSectionHeader') }}
                            </section-header>
                            <div class="text-start row">
                                <div
                                    v-for="(termName, index) in paybackTermInYearsChoices"
                                    :key="termName"
                                >
                                    <div class="custom-control custom-radio custom-control-tight mt-2">
                                        <input
                                            :id="termName"
                                            v-model="paybackTermChoice"
                                            @change="setPaybackTermsAndGetLoadDetails()"
                                            :name="termName"
                                            :value="termName"
                                            class="custom-control-input col"
                                            type="radio"
                                            :data-testid="`radio-term-choice-${index}`"
                                        >
                                        <label
                                            :for="termName"
                                            :class="['custom-control-label custom-control-label-tight']"
                                            :data-testid="`label-term-choice-${index}`"
                                        >
                                            <span class="fw-bold">
                                                {{
                                                    termName !== PAYBACK_METHODS.standardPlan
                                                        ? $t('pages.marketing.paymentCalculator.fixedMonthlyPlanRadioTitle')
                                                        : $t('pages.marketing.paymentCalculator.standardPlanRadioTitle')
                                                }}
                                            </span>
                                            <span class="text-muted">
                                                {{
                                                    termName !== PAYBACK_METHODS.standardPlan
                                                        ? $t('pages.marketing.paymentCalculator.fixedMonthlyPlanRadioBody')
                                                        : $t('pages.marketing.paymentCalculator.standardPlanRadioBody')
                                                }}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="submitting"
                            class="d-flex justify-content-center align-items-center py-4"
                        >
                            <span class="spinner-border spinner-border-sm mt-4" />
                        </div>
                        <div
                            v-else-if="showTable"
                            class="mt-5 mt-md-7"
                            data-testid="payment-calculator-table-container"
                        >
                            <div>
                                <div class="card mb-2 border-dark border-top-0">
                                    <div
                                        class="section-header fw-bold rounded-top bg-dark text-white text-center py-1"
                                        :data-testid="`payment-calculator-term-title`"
                                    >
                                        {{ termTitle }}
                                    </div>
                                    <table class="table rounded-bottom border-dark">
                                        <tbody>
                                            <tr class="">
                                                <td
                                                    class="fw-bold border-dark"
                                                    :data-testid="`payment-calculator-term-subtitle`"
                                                    v-html="termSubtitle"
                                                />
                                                <td class="text-end">
                                                    Total<br>Paid
                                                </td>
                                                <td class="text-end">
                                                    Months<br>Payback
                                                </td>
                                            </tr>
                                            <tr
                                                v-for="([key, term], index) in paybackStats.entries()"
                                                :key="key"
                                            >
                                                <td
                                                    class="fw-bold border-0 pb-0"
                                                    :data-testid="`payment-calculator-term-payback-minimum-${index}`"
                                                >
                                                    {{ toFormattedUSD(term.paybackMinAmount) }}
                                                </td>
                                                <td
                                                    class="border-0 text-end pb-0"
                                                    :data-testid="`payment-calculator-term-payback-total-${index}`"
                                                >
                                                    {{ toFormattedUSD(term.paybackTotal) }}
                                                </td>
                                                <td
                                                    class="border-0 text-end pb-0"
                                                    :data-testid="`payment-calculator-term-payback-total-${index}`"
                                                >
                                                    {{ term.paybackTermInMonths }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p class="small lh-sm text-muted m-0 px-2 pb-2">
                                        {{ termFootnote }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 mt-md-7">
                            <div
                                v-show="paybackTermChoice === PAYBACK_METHODS.standardPlan"
                                data-testid="payment-calculator-standard-plan-faq"
                            >
                                <div class="mt-4">
                                    <span v-html="$t('pages.marketing.paymentCalculator.minPaymentBody')" />
                                </div>
                            </div>
                            <div
                                v-show="paybackTermChoice === PAYBACK_METHODS.fixedMonthlyPlan"
                                data-testid="payment-calculator-fixed-plan-faq"
                            >
                                <div class="mt-4">
                                    <span class="fw-bold">{{ $t('pages.marketing.paymentCalculator.howIsItCalculatedTitle') }}</span>
                                </div>
                                <div
                                    class="mt-1"
                                    v-html="$t('pages.marketing.paymentCalculator.howIsItCalculatedBody', { aprPlus2Percent })"
                                />
                            </div>
                            <div class="mt-4">
                                <span class="fw-bold">
                                    {{ $t('pages.marketing.paymentCalculator.payItDownFasterTitle') }}
                                </span>
                            </div>
                            <div class="mt-1">
                                <span v-html="$t('pages.marketing.paymentCalculator.payItDownFasterText')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section-block>
    </marketing-layout>
</template>

<script>
    import Marketing from '@/layouts/Marketing'
    import MarketingHero from '@/components/MarketingHero'
    import FormFieldCurrency from '@/components/base/FormFieldCurrency'
    import { logger } from '@/utils/logger'
    import FormAprField from '@/components/base/FormFieldAPR'
    import { getTheoreticalPaymentStats } from '@/services/homeApi'
    import format from '@/mixins/format'
    import FormContainer from '@/components/base/FormContainer'
    import { getDocumentForSessionAndOpen } from '@/utils/document'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { LegalDocumentTypes } from '@/services/api'
    import SectionHeader from '@/components/SectionHeader'
    import SectionBlock from '@/components/SectionBlock'
    import { i18n } from '@/utils/i18n'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { calculateTotalPlusBtOrCoFee, getTheoreticalFixedMonthlyPayments } from '@/utils/paymentUtils'

    const PAYBACK_METHODS = {
        fixedMonthlyPlan: 'fixedMonthlyPlan',
        standardPlan: 'standardPlan',
    }

    const STANDARD_PLAN_TERM_IN_YEARS = 30

    export default {
        name: 'PaymentCalculator',
        components: {
            SectionBlock,
            SectionHeader,
            FormContainer,
            FormAprField,
            FormFieldCurrency,
            'marketing-layout': Marketing,
            MarketingHero,
        },
        mixins: [format, experimentsMixin],
        data() {
            return {
                cardBalance: null,
                cardAPR: null,
                paybackTermChoice: null,
                isStandardPlan: false,
                PAYBACK_METHODS,
                STANDARD_PLAN_TERM_IN_YEARS,
                paybackTermInYearsChoices: [PAYBACK_METHODS.standardPlan, PAYBACK_METHODS.fixedMonthlyPlan],
                submitting: false,
                LegalDocumentTypes,
                paybackStats: [],
                showTable: false,
                paybackTerms: [],
                disableWhileLoadingStats: false,
                termTitle: '',
                termSubtitle: '',
                termFootnote: '',
            }
        },
        watch: {
            cardBalance: async function () {
                await this.setPayBackTerms()
                await this.validateAndGetLoanDetails()
            },
            cardAPR: async function () {
                await this.validateAndGetLoanDetails()
            },
        },
        methods: {
            getDocument: async function (docType) {
                try {
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForSessionAndOpen(docType)
                } catch (e) {
                    logger.error(`failed to open pdfs document`, e)
                    ApiErrorHandler(e)
                }
            },
            clearRadioSelection: function () {
                this.paybackTermChoice = null
                this.paybackTerms.splice(0)
            },
            setPaybackTermsAndGetLoadDetails: async function () {
                await this.setPayBackTerms()
                await this.validateAndGetLoanDetails()
            },
            setPayBackTerms: async function () {
                this.paybackTerms.splice(0)
                switch (this.paybackTermChoice) {
                    case PAYBACK_METHODS.standardPlan:
                        this.paybackTerms = [
                            {
                                plan: this.paybackTermChoice,
                                months: 30 * 12,
                            },
                        ]
                        break
                    case PAYBACK_METHODS.fixedMonthlyPlan:
                        this.paybackTerms =
                            this.cardBalance <= 25_000
                                ? [
                                    {
                                        plan: this.paybackTermChoice,
                                        months: 5 * 12,
                                    },
                                    {
                                        plan: this.paybackTermChoice,
                                        months: 10 * 12,
                                    },
                                ]
                                : [
                                    {
                                        plan: this.paybackTermChoice,
                                        months: 10 * 12,
                                    },
                                    {
                                        plan: this.paybackTermChoice,
                                        months: 15 * 12,
                                    },
                                ]
                }
            },
            validateAndGetLoanDetails: async function () {
                this.paybackStats.splice(0)
                try {
                    this.disableWhileLoadingStats = true
                    this.submitting = true
                    this.showTable = false

                    for (const [id, term] of this.paybackTerms.entries()) {
                        const valid = await this.$refs.cardDetails.$refs.observer.validate()
                        if (!valid) {
                            this.submitting = false
                            return
                        }

                        /*
                            Solves https://app.asana.com/0/1127284905527130/1201790938410159/f
                            See details in asana to reproduce bug
                         */
                        if (this.cardBalance <= 1 || isNaN(this.cardBalance) || isNaN(this.cardAPR)) {
                            logger.log(`cardBalance: ${this.cardBalance}, cardAPR: ${this.cardAPR}. returning`)
                            this.submitting = false
                            return
                        }

                        await this.getDetailsByPlan(term, id)

                        this.showTable = true
                    }
                } catch (e) {
                    logger.warn(`Fetching min payment stats failed for apr: ${this.cardAPR} amount: ${this.cardBalance}`, e)
                } finally {
                    this.submitting = false
                    this.disableWhileLoadingStats = false
                }
            },
            async getDetailsByPlan(term, id) {
                const termYears = term.months / 12

                logger.info(`Fetching min payment terms for ${term.plan} plan with ${termYears} years`)

                if (term.plan === PAYBACK_METHODS.standardPlan) {
                    const standardPlanResponse = await getTheoreticalPaymentStats(this.cardAPR / 100, this.cardBalance)
                    logger.info(standardPlanResponse)

                    if (this.paybackTermChoice !== PAYBACK_METHODS.standardPlan) {
                        logger.info('Radio button changed, dont update reactive state for standard plan')
                        // radio button changed, don't update reactive state
                        // this is needed when a user clicks back and forth between radio buttons
                        // and one of the api calls im still in flight
                        return
                    }

                    this.termTitle = i18n.t('pages.marketing.paymentCalculator.standardPlanTableTitle')
                    this.termSubtitle = i18n.t('pages.marketing.paymentCalculator.standardPlanMinPaymentTitle')
                    this.termFootnote = i18n.t('pages.marketing.paymentCalculator.standardPlanTableFootnote')

                    this.$set(this.paybackStats, id, {
                        paybackMinAmount: standardPlanResponse.data.payload.firstPayment,
                        paybackTotal: calculateTotalPlusBtOrCoFee(standardPlanResponse.data.payload.totalPaid, this.cardBalance, this.heraclesParameter.BALANCE_TRANSFER_FEE_PERCENT),
                        paybackTermInYears: standardPlanResponse.data.payload.numPeriods / 12,
                        paybackTermInMonths: standardPlanResponse.data.payload.numPeriods,
                    })
                } else {
                    const fixedPlanResponse = await getTheoreticalFixedMonthlyPayments(this.cardAPR, this.cardBalance, term.months, this.heraclesParameter.BALANCE_TRANSFER_FEE_PERCENT)
                    logger.info(fixedPlanResponse)

                    if (this.paybackTermChoice === PAYBACK_METHODS.standardPlan) {
                        logger.info('Radio button changed, dont update reactive state for fixed plan')
                        // radio button changed, don't update reactive state
                        // this is needed when a user clicks back and forth between radio buttons
                        // and one of the api calls im still in flight
                        return
                    }

                    this.termTitle = i18n.t('pages.marketing.paymentCalculator.fixedPlanTableTitle')
                    this.termSubtitle = i18n.t('pages.marketing.paymentCalculator.fixedPlanMinPaymentTitle')
                    this.termFootnote = i18n.t('pages.marketing.paymentCalculator.fixedPlanTableFootnote')

                    this.$set(this.paybackStats, id, {
                        paybackMinAmount: fixedPlanResponse.monthlyPayment,
                        paybackTotal: fixedPlanResponse.totalPlusBtOrCoFee,
                        paybackTermInMonths: term.months,
                    })
                }
            },
        },
        computed: {
            aprPlus2Percent: function () {
                return `${Number(this.cardAPR) + 2}%`
            },
        },
    }
</script>

<style lang="scss" scoped>
    .min-payment-table {
        border: 1px solid lightgrey;
        border-radius: 16px;

        .list-group {
            width: 40%;

            &:first-child {
                width: 33%;
            }
        }

        .list-group-item {
            padding: 8px 0;
        }
    }
</style>
