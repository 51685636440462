<template>
    <div>
        <validation-provider
            mode="eager"
            v-slot="{ errors }"
            :vid="name"
            :rules="validationRules"
        >
            <div>
                <div class="input input-group has-validation form-floating">
                    <input
                        type="text"
                        inputmode="decimal"
                        class="form-control"
                        :id="name"
                        auto-complete="off"
                        :class="{ 'is-invalid': errors[0], 'rounded-bottom-0': appendBottomText }"
                        :name="name"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        :value="value"
                        @input="$emit('input', $event.target.value)"
                        aria-describedby="apr-input-percentage-addon"
                        :data-testid="dataTestid"
                    >
                    <label
                        v-if="label"
                        :for="name"
                    >{{ label }}</label>
                    <span
                        class="input-group-text"
                        id="apr-input-percentage-addon"
                    >%</span>
                    <div
                        class="invalid-feedback"
                        data-testid="form-field-apr-error"
                    >
                        {{ errors[0] }}
                    </div>
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    /**
     * Use for all text input fields. Has label, validation, placeholder and filters.

     * @prop{String} name (required) To modify the text formatting. Can be 'uppercase|lowercase'.

     * @prop{String} validation (optional) A string with the vee-validate rules string. ie 'required|max:7'
     * @prop{String} label (optional) A label above the input.
     * @prop{String} classes (optional) A string of any classes you want to apply to the input field
     * @prop{String} placeholder (optional) The text when the input is blank.
     * @prop{String} filter (optional) To modify the text formatting. Can be 'uppercase|lowercase'.
     * @prop{Boolean} focus (optional) To set focus on the input. Defaults to false.
     */
    import { ValidationProvider } from 'vee-validate'
    import { formInputMixin } from '@/mixins/formInputMixin'

    export default {
        name: 'FormAprField',
        inheritAttrs: false,
        mixins: [formInputMixin],
        props: {
            placeholder: {
                type: String,
                default: '0.00%',
            },
            validationRules: { type: String, default: 'required' },
            appendBottomText: { type: String, default: '' },
            dataTestid: { type: String },
        },
        components: {
            'validation-provider': ValidationProvider,
        },
    }
</script>
