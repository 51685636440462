import { httpClient } from '@/utils/http-client'
import { logger } from '@/utils/logger'
import { IGetApplicantReturnTokenByPhoneNumberAndDOBResponse } from '@/services/api/IGetApplicantReturnTokenByPhoneNumberAndDOBResponse'

enum PostCoOwnerInfoError {
    INVALID_US_PHONE_NUMBER_ERROR = 'INVALID_US_PHONE_NUMBER_ERROR',
    ERROR_COOWNER_NOT_FOUND = 'ERROR_COOWNER_NOT_FOUND',
    ERROR_APPLICANT_PHONE_CANNOT_BE_SHARED = 'ERROR_APPLICANT_PHONE_CANNOT_BE_SHARED', // This occurs when a phone number is in use by someone else on the same loan application
    PHONE_NUMBER_ALREADY_EXISTS = 'PHONE_NUMBER_ALREADY_EXISTS', // This only occurs when phone number is in use by an active aven card member
    ERROR_APPLICANT_PHONE_BELONGS_TO_PRIMARY = 'ERROR_APPLICANT_PHONE_BELONGS_TO_PRIMARY',
    ERROR_APPLICANT_PHONE_BELONGS_TO_COAPPLICANT = 'ERROR_APPLICANT_PHONE_BELONGS_TO_COAPPLICANT',
    ERROR_APPLICANT_NAME_ALREADY_EXISTS = 'ERROR_APPLICANT_NAME_ALREADY_EXISTS',
}

// Keep in sync with aven_backend/src/entity/applicant.ts
export enum ParentApplicantRelationshipType {
    MARRIED = 'married',
    OTHER = 'other',
}

const postCoOwnerInfo = async (phoneNumber: string, firstName: string, lastName: string, consentCardCreation: boolean, parentApplicantRelationship: ParentApplicantRelationshipType) => {
    return await httpClient.post('/coOwnerInfo', {
        phoneNumber,
        firstName,
        lastName,
        consentCardCreation,
        parentApplicantRelationship,
    })
}

const postCoApplicantDetails = async (phoneNumber: string, firstName: string, lastName: string, parentApplicantRelationship: ParentApplicantRelationshipType) => {
    return await httpClient.post('/coApplicantDetails', {
        phoneNumber,
        firstName,
        lastName,
        parentApplicantRelationship,
    })
}

const postDisputeCoOwnerInfo = async (disputeMessage: string) => {
    return await httpClient.post('/disputeCoOwnerInfo', { disputeMessage })
}

const postSecondaryApplicantInfo = async (signTogether: boolean) => {
    return await httpClient.post('/secondaryApplicantInfo', {
        signTogether,
    })
}

const getCoOwnerInfo = async () => {
    return await httpClient.get('/coOwnerInfo')
}

const getSecondaryApplicantInfo = async () => {
    return await httpClient.get('/secondaryApplicantInfo')
}

export interface HmdaAnswers {
    sex: string
    ethnicity: string
    race: string
}
const postHmdaAnswers = async (primaryHmdaAnswers: HmdaAnswers, secondaryHmdaAnswers?: HmdaAnswers) => {
    const postBody = {
        primaryHmdaAnswers,
    } as {
        primaryHmdaAnswers: HmdaAnswers
        secondaryHmdaAnswers?: HmdaAnswers
    }
    if (secondaryHmdaAnswers) {
        postBody.secondaryHmdaAnswers = secondaryHmdaAnswers
    }
    return await httpClient.post('/hmdaAnswers', postBody)
}

const postMaritalStatus = async (maritalStatus: string) => {
    const postBody = {
        maritalStatus,
    }
    return await httpClient.post('/maritalStatus', postBody)
}

const postFloodInsurance = async (floodInsuranceImageBase64: string) => {
    return await httpClient.post('/floodInsurancePhoto', {
        base64: floodInsuranceImageBase64,
    })
}

const getCoApplicantLegalDocument = async (docType: string) => {
    return await httpClient.get('/coApplicantLegal', {
        params: {
            docType: docType,
        },
    })
}

const coApplicantSignLegalAgreement = async (docType: string, signature: string) => {
    return await httpClient.post('/coApplicantLegal', {
        docType,
        signatureBase64: signature,
    })
}

const getCoApplicantDocument = async (docType: string) => {
    return await httpClient.get('/legal/coApplicantDocument', {
        responseType: 'blob',
        params: {
            docType: docType,
        },
    })
}

const getPropertyValuationDocumentLink = async () => {
    return await httpClient.get('/legal/propertyValuationDocLink')
}

enum ProductCategory {
    basic = 'basic',
    prime = 'prime',
}

const runAvenCardOffer = async (payload: { isPreQualification: boolean; purpose: string }) => {
    return await httpClient.post('/origination/runAvenCardOffer', payload)
}

// Keep in sync with same enum in aven_backend/src/controller/updateLoanApplicationController.ts
enum OfferFailureCode {
    homeOwnership = 'homeOwnership',
    homeOwnershipRemedy = 'homeOwnershipRemedy',
    homeIsLLC = 'homeIsLLC',
    homeIsTrustAndRin = 'homeIsTrustAndRin',
    homeIsTrustAndNOO = 'homeIsTrustAndNOO',
    homeIsTrustInNotSupportedState = 'homeIsTrustInNotSupportedState',
    humanInvestigate = 'humanInvestigate',
    denied = 'denied',
    expired = 'expired',
    priorApplicationFound = 'priorApplicationFound',
    offerAddCoApplicant = 'offerAddCoApplicant',
    blockedApplication = 'blockedApplication',
    underwritingFail = 'underwritingFail',
}

const getAvenCardOffer = async (createdAfter?: string) => {
    const config = { params: { createdAfter } }
    return await httpClient.get('/origination/getAvenCardOffer', config)
}

const beginHomeDataFetchAndPreQualificationOffer = async (payload: { isDmPrequal: boolean; purpose: string }) => {
    return await httpClient.post('/origination/beginHomeDataFetchAndPreQualificationOffer', payload)
}

const beginHomeQualOffer = async (payload: { purpose: string }) => {
    return await httpClient.post('/origination/beginHomeQualOffer', payload)
}

const getHomeOffer = async (jobId: string) => {
    const config = { params: { jobId } }
    return await httpClient.get('/origination/getHomeOffer', config)
}

const acceptOrDeclineOffer = async (acceptOffer: boolean) => {
    logger.log('Does user explicitly accept the offer? ' + acceptOffer)
    return await httpClient.post('/underwriting/acceptOrDeclineOffer', {
        acceptOffer,
    })
}
export const EXTENDED_LINES_LINE_SIZE_THRESHOLD = 100_000
export interface LoanTerms {
    apr: number // On the form 4% -> 4.00 on credit_card_customer, but 0.0400 on loan application. TODO: fix this inconsistency
    margin: number // On the form 4% -> 4.00 on credit_card_customer, but 0.0400 on loan application. TODO: fix this inconsistency
    lineSize: number
    cashBack: number
    monthlyFee: number
    product: ProductCategory
    policyName: string
    contingencies: OfferContingency[]
}
export enum OfferContingency {
    titleInsurance = 'titleInsurance',
    employmentVerification = 'employmentVerification',
    hazardInsurance = 'hazardInsurance',
}

const getTheoreticalPaymentStats = async (apr: number, lineSize: number) => {
    return await httpClient.get('/origination/getTheoreticalPayment', { params: { apr, lineSize } })
}

const getNonEntityOwnerNames = async () => {
    return await httpClient.get('/origination/getNonEntityOwnerNames')
}

const getMonthlyInstallmentPaymentStatsFromCoreCard = async (apr: number, amount: number, loanTermInMonths: number) => {
    return await httpClient.get('/origination/getMonthlyInstallmentPaymentStatsFromCoreCard', { params: { apr, amount, loanTermInMonths } })
}

const getApplicantReturnTokenUsingPhoneNumberAndDOB = async (phoneNumber: string, dateOfBirth: string) => {
    return (await httpClient.post('/getApplicantReturnTokenByPhoneNumberAndDOB', { phoneNumber, dateOfBirth })) as IGetApplicantReturnTokenByPhoneNumberAndDOBResponse
}

const getPropertyDetails = async () => {
    return await httpClient.get('/home/propertyDetails')
}

export {
    PostCoOwnerInfoError,
    ProductCategory,
    beginHomeQualOffer,
    getCoApplicantLegalDocument,
    coApplicantSignLegalAgreement,
    getPropertyValuationDocumentLink,
    postHmdaAnswers,
    postMaritalStatus,
    postFloodInsurance,
    postCoOwnerInfo,
    postCoApplicantDetails,
    postDisputeCoOwnerInfo,
    getCoApplicantDocument,
    OfferFailureCode,
    getAvenCardOffer,
    acceptOrDeclineOffer,
    postSecondaryApplicantInfo,
    getCoOwnerInfo,
    getSecondaryApplicantInfo,
    getNonEntityOwnerNames,
    getMonthlyInstallmentPaymentStatsFromCoreCard,
    getTheoreticalPaymentStats,
    getApplicantReturnTokenUsingPhoneNumberAndDOB,
    beginHomeDataFetchAndPreQualificationOffer,
    getHomeOffer,
    getPropertyDetails,
}
