import Vue from 'vue'
import throttle from 'lodash/throttle'
import { logger } from '@/utils/logger'

export default Vue.extend({
    methods: {
        throttleLogging: throttle((inputValue, logPrefix = ''): any => {
            if (inputValue) {
                logger.info(`${logPrefix}: ${inputValue}`)
            }
        }, 1000),
        throttleAnalytics: throttle((eventName, eventProps): any => {
            if (eventName) {
                window.logEvent(eventName, eventProps)
            }
        }, 4000),
    },
})
